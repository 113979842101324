.component__swap {
  position: relative;

  .component__swap__middle {
    .component__swap__middle__down {
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: auto;
    }
  }

  .component__swap__btn_connect_wallet {
    border-radius: 0.25rem;
    height: 3.125rem;
    font-size: 1.15rem;
  }

  .component__swap__details__special__line {
    width: calc(100% + 2rem);
    height: 1rem;
    position: absolute;
    top: 100%;
    left: -1rem;
    z-index: 2;
    box-shadow: 0px 4px 4px rgba(168, 168, 168, 0.65);
  }

  .component__swap__details {
    position: absolute;
    left: 0;
    right: 0;
    top: 101%;
    border-radius: 0.5rem;
    background-color: white;
    box-shadow: 0px 3px 20px rgba(186, 201, 209, 0.5);
  }
}
