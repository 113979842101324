@import "./breakpoints.scss";
html {
  --bg-primary: rgb(240, 244, 247);
  --bg-secondary: #f2f4f5;
  --bg-third: rgb(0, 225, 0);
  --bg-green-10: rgb(217, 245, 220);
  --bg-white: #fff;
  --bg-white-10: rgb(243, 247, 251);
  --bg-white-20: rgb(236, 242, 246);
  --bg-white-30: rgb(247, 248, 250);
  --bg-white-40: rgb(204, 204, 204);
  --bg-gray: rgb(240, 244, 247);
  --c-gray: rgb(102, 102, 102);
  --c-gray-10: rgb(153, 153, 153);
  --c-gray-20: rgb(128, 128, 128);
  --c-gray-30: rgb(51, 51, 51);
  --c-gray-40: rgb(80, 80, 80);
  --c-gray-50: rgb(233, 234, 236);
  --c-warn-1: rgb(211, 74, 74);
  --app-width: 750px;
  --avatar-width: 1.75rem;
  --title-base-size: 1rem;
  --title-1-size: 2.5rem;
  --title-2-size: 1.875rem;
  --title-3-size: 1.5rem;
  --title-4-size: 1rem;
  --line-height-1: 1.8;
  --hover-bg-1: #fafafa;
  font-size: 16px;

  @media screen and (max-width: 600px) {
    font-size: 14px;
  }

  @media screen and (max-width: 420px) {
    font-size: 12px;
  }

  body {
    margin: 0;
    padding: 0;
    font-family: "Roboto Mono", monospace;
    background-color: var(--bg-gray);
    .screen {
      max-width: var(--app-width);
      margin: 0 auto;
    }
    .title-main-size {
      font-size: var(--title-1-size);
    }
    .line-height-1 {
      line-height: var(--line-height-1);
    }
    .modal-primary {
      .ant-modal-content {
        background-color: var(--bg);
        box-shadow: unset;
      }
    }
    .bg-primary {
      background: var(--bg-primary);
    }
    .bg-secondary {
      background-color: var(--bg-secondary);
    }
    .bg-third {
      background-color: var(--bg-third);
    }
    .bg-lightGrey {
      background-color: #f1f1f3;
    }
    .bg-white {
      background-color: var(--bg-white);
    }
    .bg-white-10 {
      background-color: var(--bg-white-10);
    }
    .bg-white-20 {
      background-color: var(--bg-white-20);
    }
    .bg-white-30 {
      background-color: var(--bg-white-30);
    }
    .bg-green-10 {
      background-color: var(--bg-green-10);
    }
    .bg-gray {
      background-color: var(--bg-gray);
    }
    .c-white {
      color: white;
    }
    .c-third {
      color: var(--bg-third);
    }
    .c-gray {
      color: var(--c-gray);
    }
    .c-gray-10 {
      color: var(--c-gray-10);
    }
    .c-gray-20 {
      color: var(--c-gray-20);
    }
    .c-gray-30 {
      color: var(--c-gray-30);
    }
    .c-gray-40 {
      color: var(--c-gray-40);
    }
    .c-black {
      color: black;
    }
    .c-warn-1 {
      color: var(--c-warn-1);
    }
    .border-white-20 {
      border-color: var(--bg-white-20) !important;
    }
    .border-third {
      border-color: var(--bg-third);
    }
    .app-avatar {
      width: var(--avatar-width) !important;
      height: var(--avatar-width) !important;
    }
    .ant-btn-primary[disabled] {
      background-color: var(--bg-white-40);
      color: white;
    }
    .img-responsive {
      max-width: 100%;
      height: auto;
    }
    .app-frame {
      width: 420px;
      margin: auto;
      @media screen and (max-width: 420px) {
        width: 90%;
      }
    }
    .app-frame-content {
      padding-top: 14vh;
      @media screen and (max-width: 600px) {
        padding-top: 100px;
      }
      @media screen and (max-width: 450px) {
        padding-top: 50px;
      }
    }
    .app-box-shadow {
      -webkit-box-shadow: 0px 0px 11px 1px rgba(168, 168, 168, 0.65);
      -moz-box-shadow: 0px 0px 11px 1px rgba(168, 168, 168, 0.65);
      box-shadow: 0px 0px 11px 1px rgba(168, 168, 168, 0.65);
    }

    .ant-btn:hover,
    .ant-btn:focus {
      color: var(--bg-third);
      border-color: var(--bg-third);
    }
    .ant-popover {
      z-index: 88 !important;
    }
    .ant-popover-inner {
      border-radius: 0.25rem;
    }
    .ant-popover-title {
      border-bottom: none;
    }
    .ant-btn-primary {
      background-color: var(--bg-third);
      border-color: var(--bg-third);
      &:hover,
      &:focus {
        background-color: white;
      }
    }
    .modal__token__select {
      top: 10rem;
      .ant-modal-content {
        border-radius: 0.25rem;
        overflow: hidden;
        .ant-modal-close {
          .ant-modal-close-x {
            height: 2.5rem;
            width: 2.5rem;
            line-height: 2.5rem;
          }
        }

        .ant-modal-header {
          border-bottom: none;
          padding: 1rem;
        }
        .ant-modal-body {
          padding: 1rem;
          padding-top: 0;
          .ant-input {
            font-weight: 300;
            border-radius: 0;
            padding: 0.75rem;
            font-size: 18px;
            border-color: var(--bg-white-40);
            margin-bottom: 1rem;
            color: var(--c-gray-30);
            &:hover,
            &:focus {
              border-color: var(--bg-third);
              box-shadow: none;
            }
          }
          .ant-divider-horizontal {
            margin: 1rem 0;
          }
        }
        .ant-modal-footer {
          display: none;
        }
      }
    }

    // add liquidity confirm modal
    .modal__add_liquidity_confirm,
    .modal__poll_info_liquidity {
      top: 16rem;
      .ant-modal-content {
        border-radius: 0.25rem;
        overflow: hidden;
        .ant-modal-close {
          .ant-modal-close-x {
            height: 2.5rem;
            width: 2.5rem;
            line-height: 2.5rem;
          }
        }

        .ant-modal-header {
          border-bottom: none;
          padding: 1rem;
        }
        .ant-modal-body {
          padding: 0;

          .ant-divider-horizontal {
            margin: 1rem 0;
          }
          button {
            border-radius: 0.25rem;
            height: 50px;
            font-size: 20px;
            font-weight: 300;
          }
        }
        .ant-modal-footer {
          display: none;
        }
      }
    }

    .modal__add_liquidity_confirm {
      top: 10rem;
    }

    // add liquidity transaction submitted
    .modal__add_liquidity_submitted {
      top: 10rem;
      .ant-modal-content {
        border-radius: 0.25rem;
        overflow: hidden;
        .ant-modal-close {
          .ant-modal-close-x {
            height: 2.5rem;
            width: 2.5rem;
            line-height: 2.5rem;
          }
        }

        .ant-modal-header {
          border-bottom: none;
          padding: 1rem;
          opacity: 0;
        }
        .ant-modal-body {
          padding: 0;

          .ant-divider-horizontal {
            margin: 1rem 0;
          }
          button {
            border-radius: 0.25rem;
            height: 50px;
            font-size: 20px;
            font-weight: 300;
          }
          .modal__add_liquidity_submitted__arrow {
            border-radius: 50%;
            border-width: 1px;
          }
        }
        .ant-modal-footer {
          display: none;
        }
      }
    }

    // transaction submitted modal
    .modal__transaction_submitted {
      top: 16rem;
      .ant-modal-content {
        border-radius: 0.25rem;
        overflow: hidden;
        .ant-modal-close {
          .ant-modal-close-x {
            height: 2.5rem;
            width: 2.5rem;
            line-height: 2.5rem;
          }
        }

        .ant-modal-header {
          border-bottom: none;
          padding: 1rem;
          opacity: 0;
        }
        .ant-modal-body {
          padding: 0;

          .ant-divider-horizontal {
            margin: 1rem 0;
          }
          button {
            border-radius: 0.25rem;
            height: 50px;
            font-size: 20px;
            font-weight: 300;
          }
          .modal__transaction_submitted__arrow {
            border-radius: 50%;
            border-width: 1px;
          }
        }
        .ant-modal-footer {
          display: none;
        }
      }
    }

    // Swap Confirm Modal
    .modal__swap_confirm {
      top: calc((100vh - 30rem) / 2);
      .ant-modal-content {
        border-radius: 0.25rem;
        overflow: hidden;
        .ant-modal-close {
          .ant-modal-close-x {
            height: 2.5rem;
            width: 2.5rem;
            line-height: 2.5rem;
          }
        }

        .ant-modal-header {
          border-bottom: none;
          padding: 1rem;
        }
        .ant-modal-body {
          padding: 0;

          .ant-divider-horizontal {
            margin: 1rem 0;
          }
          button {
            border-radius: 0.1rem;
          }
        }
        .ant-modal-footer {
          display: none;
        }
        .modal__swap_confirm__top {
          .modal__swap_confirm__top__arrow {
            margin-left: calc(var(--avatar-width) / 2 - 7px);
          }
        }
        .modal__swap_confirm__bottom {
          .modal__swap_confirm__bottom__confirm {
            height: 50px;
            font-size: 20px;
          }
        }
      }
    }

    .modal__account_change {
      top: 10rem;
      .ant-modal-content {
        border-radius: 0.25rem;
        overflow: hidden;
        .ant-modal-close {
          .ant-modal-close-x {
            height: 2.5rem;
            width: 2.5rem;
            line-height: 2.5rem;
          }
        }

        .ant-modal-header {
          border-bottom: none;
          padding: 1rem;
        }
        .ant-modal-body {
          padding: 0;

          .ant-divider-horizontal {
            margin: 1rem 0;
          }
          .modal__account_change__account_item__change {
            border-radius: 0.25rem;
            font-weight: thin;
          }
          .modal__account_change__account_item {
            border: 1px solid;
          }
          .modal__account_change__account_item__view_on_iotexscan {
            color: var(--c-gray-20) !important;
            &:hover {
              span {
                text-decoration: underline;
              }
            }
          }
        }
        .ant-modal-footer {
          display: none;
        }
      }
    }

    .hide-on-mobile {
      @media screen and (max-width: 600px) {
        display: none;
      }
    }

    // remove liquidity confirm modal
    .modal__remove_liquidity_confirm {
      top: 12rem;
      .ant-modal-content {
        border-radius: 0.25rem;
        overflow: hidden;
        .ant-modal-close {
          .ant-modal-close-x {
            height: 2.5rem;
            width: 2.5rem;
            line-height: 2.5rem;
          }
        }

        .ant-modal-header {
          border-bottom: none;
          padding: 1rem;
        }
        .ant-modal-body {
          padding: 0;

          .ant-divider-horizontal {
            margin: 1rem 0;
          }
          button {
            border-radius: 0.25rem;
            height: 50px;
            font-size: 20px;
            font-weight: 300;
          }
        }
        .ant-modal-footer {
          display: none;
        }
      }
    }

    // remove liquidity transaction submitted
    .modal__remove_liquidity_submitted {
      top: 10rem;
      .ant-modal-content {
        border-radius: 0.25rem;
        overflow: hidden;
        .ant-modal-close {
          .ant-modal-close-x {
            height: 2.5rem;
            width: 2.5rem;
            line-height: 2.5rem;
          }
        }

        .ant-modal-header {
          border-bottom: none;
          padding: 1rem;
          opacity: 0;
        }
        .ant-modal-body {
          padding: 0;

          .ant-divider-horizontal {
            margin: 1rem 0;
          }
          button {
            border-radius: 0.25rem;
            height: 50px;
            font-size: 20px;
            font-weight: 300;
          }
          .modal__remove_liquidity_submitted__arrow {
            border-radius: 50%;
            border-width: 1px;
          }
        }
        .ant-modal-footer {
          display: none;
        }
      }
    }
  }
}
