.page__import_pool {
  padding-top: 8rem !important;
  .page__import_pool__content {
    position: relative;
    .page__import_pool__content__header {
      button {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .page__import_pool__content__main {
    }
  }
}
