.layout__app {
  height: 100vh;

  .ant-layout-header {
    background-color: white;
  }

  .layout__app__content {
    height: 100%;
  }
}
