.component__settings_popover__title {
}

.component__settings_popover__content {
  max-width: 21.875rem;
  padding: 1rem 0.75rem;

  .component__settings_popover__content__tolerance__row {
    & > * + * {
      margin-left: 0.5rem;
    }
  }

  .settings__btn {
    padding: 0.25rem 0;
    border: none;
  }

  .settings__network__btns {
    button {
      font-weight: 300;
      border: none;
      padding: 0.5rem 1rem;
      line-height: 1;
    }
  }

  .component__settings_popover__content__expert__radio_group {
    background-color: white;
    border: 1px solid #f1f1f3;
    overflow: hidden;
    border-radius: 4px;

    .ant-radio-button-wrapper {
      padding-left: 0.5rem;
      padding-right: 0.5rem;
      background-color: white;
      color: rgb(152, 152, 152) !important;
      border: none !important;
      box-shadow: none !important;

      line-height: 1.5rem;
      height: 1.5rem;
      width: 40px;
      text-align: center;

      &::before {
        display: none !important;
      }
    }

    .ant-radio-button-wrapper-checked {
      color: white !important;
      background-color: rgb(0, 225, 0);

      &:nth-child(2n) {
        // border-right-width: 0;
        // border-top-right-radius: 0;
        // border-bottom-right-radius: 0;
        background-color: var(--c-gray-20);
      }
    }
  }

  .component__settings_popover__content__transaction_deadline {
    max-width: 6.25rem;
    text-align: right;
  }
}
