.component__liquidity_item {
  border-radius: 0.5rem;
  padding: 1rem;
  border: 1px solid #f1f1f3;
  transition: all 0.3s;
  overflow: hidden;
  &.component__liquidity_item--expanded {
    background-color: #f8f8fa;
  }
  .component__liquidity_item__content {
    text-align: center;
    transition: all 0.3s;
    max-height: 0;
    opacity: 0;
    &.component__liquidity_item__content--expanded {
      max-height: 100vh;
      opacity: 1;
      padding-top: 0.5rem;
    }
    .component__liquidity_item__content__view_pool_info {
    }
    .component__liquidity_item__content__button_group {
      button {
        width: 45%;
        border-radius: 0.35rem;
        font-weight: 300;
      }
    }
  }
}
