.page__liquidity_remove {
  padding-top: 8rem !important;

  .page__liquidity_remove__content {
    position: relative;

    .page__liquidity_remove__content__header {
      button {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .page__liquidity_remove__content__main {
      .page__liquidity_remove__content__main__slider {
        padding: 6px 0;

        .ant-slider-rail {
          height: 2px;
          background-color: rgba(186, 201, 209, 0.5);
          border: 1px solid transparent;
        }

        .ant-slider-track {
          background-color: transparent;
        }

        .ant-slider-handle {
          width: 1.5rem;
          height: 1.5rem;
          background-color: rgb(0, 225, 0);
          top: 0.25rem;
          border-color: rgb(0, 225, 0) !important;
        }
      }

      .page__liquidity_remove__content__main__suply {
        border-radius: 0.25rem;
        height: 3.125rem;
        font-size: 1.25rem;

        &.btn--disabled {
          background-color: #cccbce;
          color: white;
        }
      }

      .page__liquidity_remove__content__main__approve {
        border-radius: 0.25rem;
        height: 3.125rem;
        font-size: 1.25rem;

        &.btn--disabled {
          background-color: #cccbce;
          color: white;
        }
      }
    }

    .page__liquidity_remove__content__position {
      position: absolute;
      top: calc(100% + 1rem);
      left: 0.5rem;
      right: 0.5rem;
      opacity: 1;

      .page__liquidity_remove__content__position__avatar_group {
        .ant-avatar:not(:first-child) {
          margin-left: -1rem;
        }
      }
    }
  }
}
