.page__liquidity_add {
  .page__liquidity_add__content {
    position: relative;
    .page__liquidity_add__content__header {
      button {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .page__liquidity_add__content__main {
      .page__liquidity_add__content__main__pool_and_share {
        .page__liquidity_add__content__main__pool_and_share__label {
        }
        .page__liquidity_add__content__main__pool_and_share__content {
          border: 1px solid #f2f4f5;
        }
      }
      .page__liquidity_add__content__main__suply {
        border-radius: 0.25rem;
        height: 3.125rem;
        font-size: 1.25rem;
        &.btn--disabled {
          background-color: #cccbce;
          color: white;
        }
      }
      .page__liquidity_add__content__main__approve {
        border-radius: 0.25rem;
        height: 3.125rem;
        font-size: 1.25rem;
        &.btn--disabled {
          background-color: #cccbce;
          color: white;
        }
      }
    }
    .page__liquidity_add__content__position {
      position: absolute;
      top: calc(100% + 1rem);
      left: 0.5rem;
      right: 0.5rem;
      opacity: 1;
    }
  }
}
