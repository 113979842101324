.component__header {
  background-color: white;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 999;

  .component__header__content {
    max-width: 1300px;
    height: 100%;
    width: 90%;
    .component__header__content__logo {
      height: 100%;
    }
    .component__header__content__right {
      font-size: 1.125rem;
      .component__header__content__right__wallet_connect {
        outline: none !important;
        font-size: 1.125rem;
      }
      .component__header__content__right__icon_button {
        width: 32px;
        height: 32px;
        padding: 0;
        background-image: url(../../../../public/image/icon_setting.png);
        background-repeat: no-repeat;
        background-size: 32px 32px;
        outline: none;
        &:hover,
        &:focus {
          background-image: url(../../../../public/image/icon_setting_s.png);
        }
      }
      .dynamic__icon--wrapper {
        width: 32px;
        height: 32px;
        padding: 0;
        background-image: url(../../../../public/image/icon_more.png);
        background-repeat: no-repeat;
        background-size: 32px 32px;
        outline: none;
        &:hover,
        &:focus {
          background-image: url(../../../../public/image/icon_more_s.png);
        }
      }
    }
  }
}
