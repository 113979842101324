.component__trading__pair__icon {
  position: relative;
  flex-direction: row;
  height: 1.75rem;
  .iotx__icon {
    position: absolute;
    left: 1.35rem;
    z-index: 1;
  }
  .token__icon {
    position: absolute;
    left: 0;
    background-color: #ccc;
    z-index: 2;
    border: none;
  }
}
