.component__address_input_field {
  border-color: #f2f2f4;
  border-width: 2px;
  border-radius: 0.5rem;
  padding: 0.5rem 0.75rem;
  .ant-input {
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
    font-size: 1.375rem;
    padding-right: 0.5rem;
    padding-left: 0;
    &.middle:focus {
      outline-width: 0;
    }
    &:focus {
      outline: none;
    }
  }
}
