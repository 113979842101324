.component__token_select_field {
  .ant-input-wrapper {
    outline: none !important;
    .ant-input {
      outline: none !important;
      border: none !important;
      box-shadow: none !important;
      font-size: 1.5rem;
      padding-right: 0.5rem;
      padding-left: 0;
      background-color: transparent;
      &.middle:focus {
        outline-width: 0;
      }
      &:focus {
        outline: none;
      }
    }
    .ant-input-group-addon {
      border: none;
      padding: 0;
      .component__token_input_field__token_selected {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
    }
  }
}
