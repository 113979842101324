.page__home {
  .page__home__content {
    .page__home__content__top_menu {
      border-bottom: none;
      margin-bottom: 1rem;
      .ant-menu-item {
        padding-left: 1rem;
        padding-right: 1rem;
        border-bottom: none !important;
        text-transform: capitalize;
        font-size: 1.3rem;
        color: rgb(153, 153, 153);
        &:hover,
        &:active {
          color: #0f0;
        }
      }
      .ant-menu-item-selected {
        color: #0f0;
      }
    }
  }
}
