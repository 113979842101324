.options_wrapper {
  margin: -12px -16px;
  .options_wrapper--item {
    padding: 0.8125rem 0.5rem 0.8125rem 0.5rem;
    overflow: hidden;
    color: var(--c-gray);
    &:first-child {
      border-top-left-radius: 2px;
      border-top-right-radius: 2px;
    }
    &:last-child {
      border-bottom-left-radius: 2px;
      border-bottom-right-radius: 2px;
    }
    .menu-item-icon {
      width: 1.5rem;
      height: 1.5rem;
      display: inline-block;
      margin-right: 0.3rem;
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }
    @for $i from 0 through 4 {
      .menu-item-icon#{$i} {
        background-image: url(../../../../public/image/icon_0#{$i}.png);
      }
    }
    &:link,
    &:visited {
      color: var(--c-gray);
    }
    &:hover {
      background: var(--hover-bg-1);
      // transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      color: var(--bg-third);
      @for $i from 0 through 4 {
        .menu-item-icon#{$i} {
          background-image: url(../../../../public/image/icon_0#{$i}_s.png);
        }
      }
    }
  }
}
